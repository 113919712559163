import React from 'react'
import ReactDOM from 'react-dom/client'
import OurStory from "./pages/OurStory.tsx";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import "@fontsource/afacad-flux/300.css";
import "@fontsource/afacad-flux/400.css";
import "@fontsource/afacad-flux/500.css";
import "@fontsource/afacad-flux/600.css";
import "@fontsource/afacad-flux/700.css";
import "@fontsource/afacad-flux/800.css";
import "@fontsource/afacad-flux/900.css";
import './App.css'
import Academics from "./pages/Academics.tsx";
import {Box, createTheme, ThemeProvider} from "@mui/material";
import Admissions from "./pages/Admissions.tsx";
import ContactUs from "./pages/ContactUs.tsx";
import backgroundImage from "./assets/angeline-country-day-logo.svg";

import './custom.scss';
import Careers from "./pages/Careers.tsx";

const router = createBrowserRouter([
    {
        path: "/",
        element: <OurStory/>,
    },
    {
        path: "/academics",
        element: <Academics/>,
    },
    {
        path: "/admissions",
        element: <Admissions/>,
    },
    {
        path: "/contact-us",
        element: <ContactUs/>,
    },
    {
        path: "/careers",
        element: <Careers/>,
    },
    {
        path: "*",
        element: <OurStory/>,
    },
])

const theme = createTheme({
    typography: {
        fontFamily: [
            'Afacad Flux',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Box component={"img"} sx={{
                width: '50vw',
                height: '50vh',
                position: 'fixed',
                top: '35%',
                left: '25%',

                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
                opacity: .1,
                zIndex: '-2',
            }} src={backgroundImage} alt={"background"}></Box>
            <RouterProvider router={router}/>
        </ThemeProvider>
    </React.StrictMode>,
)
