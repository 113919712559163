import {Box} from "@mui/material";
import angelineBgKid from '../assets/angeline-bg.jpg'
import angelineWords from '../assets/angeline-country-day-words.svg'

import ContentTile from "../assets/components/ContentTile.tsx";
import ResponsiveAppBar from "../assets/components/ResponsiveAppBar.tsx";
import Footer from "./Footer.tsx";

function OurStory() {
    return (
        <>
            <Box sx={{}}>
                <ResponsiveAppBar/>
                <Box className={'hero'}>
                    <Box sx={{
                        maxHeight: {xs: '90vh', sm: '100vh'},
                        display: 'flex',
                        justifyContent: 'center',
                        placeItems: 'baseline',
                        alignItems: 'center',
                        overflow: 'hidden'
                    }}>
                        <Box component={"img"} sx={{
                            width: '100vw',
                            height: '100vh',
                            objectFit: 'cover',
                            objectPosition: 'top',
                        }} alt={"child throwing sand"} src={angelineBgKid}/>
                        <Box component={"img"} sx={{
                            width: {xs: '75%', sm: '75%', md: '75%', lg: '75%',},
                            maxWidth: {xs: 300, sm: 300, md: 500, lg: 700},
                            position:'absolute',
                            bottom:30,
                            right:30,
                        }} alt={"child throwing sand"} src={angelineWords}/>
                    </Box>
                </Box>
                <ContentTile
                    id={"welcome"}
                    header={"Welcome to Angeline Country Day School"}
                    subtitle={"Rooted in Excellence, Growing Global Citizens"}
                    body={`At Angeline Country Day, we believe in blending the strength of tradition with the skills 
                    required for tomorrow’s world. We prepare our students to think critically, act with empathy and 
                    lead with confidence, while nurturing their unique talents and fostering a deep sense of community 
                    through our International Baccalaureate Primary Years Programme (IB PYP).  Unlike a traditional 
                    lecture format, students learn through discovery and problem-solving using an inquiry-based 
                    learning format that challenges them to explore and discern answers instead of just memorizing 
                    facts.\\n\\n\\n\\n`}
                    reverse={true}
                />
                <ContentTile
                    id={"mission"}
                    header={"Our Mission"}
                    subtitle={"Rooted Locally, Growing Globally"}
                    body={`Angeline Country Day School is committed to providing an education where students are 
                    encouraged to explore, question, and challenge themselves. Grounded 
                     in the rich tradition of educational excellence, our IB PYP curriculum inspires curiosity, fosters
                      independent thought, and prepares students to thrive in a rapidly changing global society.\\n\\n
                    We believe that a strong educational foundation, rooted in local values and enriched with a global
                     perspective, creates confident, compassionate leaders of tomorrow. Our approach combines robust 
                     academics with creativity, critical thinking, and personal growth.\\n\\n\\n\\n`}
                    reverse={true}
                    backgroundColor="1"
                />
                <ContentTile
                    id={"why-country-day"}
                    header={"A Day in the Life of an Angeline Student"}
                    // subtitle={""}
                    body={`<b>International Baccalaureate (IB) Excellence</b>\\n
Our IB PYP framework fosters inquiry, encourages critical thinking, and nurtures well-rounded students. The program prepares students for a lifetime of learning and leadership, with an emphasis on global perspectives.\\n\\n
<b>Academic Excellence and Innovation</b>\\n
Our curriculum is designed to challenge students, ignite their passions, and develop strong analytical and problem-solving skills. From early literacy to complex mathematical reasoning, our students grow intellectually in a supportive yet demanding environment.\\n\\n
<b>Global Learning, Local Roots</b>\\n
While we encourage our students to think globally, we remain deeply connected to our local community, fostering values of responsibility, empathy, and service.\\n\\n
<b>Balanced Development</b>\\n
Beyond academics, we focus on the whole child—developing emotional intelligence, resilience, and collaboration. Our students emerge as confident leaders, ready for the complexities of the modern world.\\n\\n`}
                    reverse={true}
                />
                <Footer/>


            </Box>


        </>
    );
}

export default OurStory
