import {Box, Link, Typography} from "@mui/material";

import ResponsiveAppBar from "../assets/components/ResponsiveAppBar.tsx";
import ContactForm from "../assets/components/ContactForm.tsx";
import letsTalk from "../assets/contact-child.jpg";
import Footer from "./Footer.tsx";

function ContactUs() {
    return (
        <>
            <Box sx={{mt:20}}>
                <ResponsiveAppBar/>
                <Box className={'tile'} sx={{mb:20,}}>
                    <Link variant={"h4"} href={"/careers"}>Interested in a career with us? Apply Here</Link>
                    <br/><br/>
                    <Box sx={{display:'flex',  flexDirection:{xs: 'column-reverse', md:'row',} }}>
                        <Box sx={{mr:{md:5, xs:0}, mt:5,}}>
                            <ContactForm/>
                        </Box>
                        <Box sx={{maxWidth:'90vw', ml:'auto', mr:'auto',}} className={`image`}>
                            <Typography sx={{marginTop:'unset',textAlign:'center',fontSize:{md:'4rem', xs:'2.5rem',}, fontWeight:'900'}}>Contact Us</Typography>
                            <Box component={"img"} src={letsTalk} alt={`Let's Talk`} sx={{maxWidth:{md: '40vw', sm:'50%', xs: '90%'} }}/>
                        </Box>

                    </Box>
                </Box>
                <Footer/>
            </Box>
        </>
    );
}

export default ContactUs
