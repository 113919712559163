import {Box} from "@mui/material";

import ResponsiveAppBar from "../assets/components/ResponsiveAppBar.tsx";
import Footer from "./Footer.tsx";
import ContentTile from "../assets/components/ContentTile.tsx";

function Admissions() {
    return (
        <>
            <Box sx={{}}>
                <ResponsiveAppBar/>

                <ContentTile
                    id={"application"}
                    header={"Admissions"}
                    subtitle={"Join Our Growing Family"}
                    body={`At Angeline Country Day, we welcome curious learners who are excited to embrace challenges, 
                    ask thoughtful questions, and grow as global citizens. We invite you to learn more about our 
                    admissions process and discover how your child can thrive in our vibrant community.\\n\\n
                    <b>Schedule a Tour</b>\\n
                    Come visit our beautiful campus and see first-hand what makes Angeline Country Day so unique.\\n 
                    Walk through our classrooms, meet our teachers, and see how we foster a love of learning.\\n\\n
                    <b>Application Process</b>\\n
                    Our admissions team is here to guide you through every step of the process. We encourage early 
                    applications, as spaces are limited.\\n\\n
                    Interested families may send an email to our admissions department to learn about possible openings
                     and schedule a tour. Applicants are considered for a class if space is available or for a position
                      on the wait list if a class is full.\\n\\n

                    <a href="mailto:admissions@angelinecountryday.org">admissions@angelinecountryday.org</a>\\n
                    <a href="tel:9543631248">954-363-1248</a>\\n\\n

                    Please note: For all campus tours both guardians and prospective students are required to be present.\\n\\n
                    
`}
                    reverse={true}
                />
                <ContentTile
                    id={"tuition"}
                    header={"Tuition & Fees"}
                    body={`At Angeline Country Day School, we offer a robust academic program through our 
                    International Baccalaureate Primary Years Programme (IB PYP), infused with classical education 
                    principles. Our tuition structure reflects the value of this unique educational experience, 
                    delivered in a nurturing and dynamic environment.\\n\\n
                    <subtitle>Tuition Rates by Age Level</subtitle>
                    <b>Preschool (Ages 2-4):</b> $13,450 per year\\n
                    This includes a comprehensive early childhood education experience, preparing our youngest learners 
                    for success in their academic journey.\\n\\n
                    <b>Kindergarten – 5th Grade:</b>  $14,850 per year\\n
                    Our K-5 program combines inquiry-based learning with a classically infused curriculum, ensuring 
                    students are equipped with the skills and knowledge needed to thrive academically and personally.\\n\\n
                    
                    <subtitle>Additional Fees</subtitle>
                    <b>Lunch Program (Optional):</b>  $1,450 per year\\n
                    Families can choose to participate in our nutritious, chef-prepared lunch program or opt to send 
                    packed lunches from home. Our lunch program is designed to cater to a variety of dietary preferences
                     and needs.\\n\\n
                    <b>Aftercare Program (Optional):</b> $1,850\\n
                    Aftercare services are available for students who need extended day support, providing enriching 
                    activities, homework help, and outdoor play. Flexible daily or monthly options are also available.\\n\\n\\n
                    
`}
                    reverse={true}
                    backgroundColor="1"
                />
                <ContentTile
                    id={"scholarships"}
                    header={"Financial Aid & Scholarships"}
                    // subtitle={""}
                    body={`                   
                    <b>Financial Aid & Scholarships</b>\\n
                    
                    Angeline Country Day School is committed to making high-quality education accessible to as many 
                    families as possible. We are proud participants in the <b>Step Up For Students Scholarship</b> program, 
                    which provides financial assistance to eligible families.\\n\\n
                    
                    <b>Step Up for Students</b>\\n

                    The Step Up For Students Scholarship helps cover tuition costs based on family income. It can 
                    significantly reduce or eliminate tuition expenses for qualifying families. For more information 
                    on eligibility and how to apply, visit the 
                    <a href="https://www.stepupforstudents.org/">Step Up For Students website</a> or reach out to our 
                    admissions office.\\n\\n

                    <b>Flexible Payment Plans</b>\\n
                    
                    To accommodate the varying financial needs of our families, we offer a range of payment options, 
                    including monthly, semi-annual, or annual payments. Our goal is to provide flexibility without 
                    compromising the quality of your child’s education.\\n\\n

                    <b>Florida Voluntary PreKindergarten Program (VPK)</b>\\n

                    We are proud to partner with the state of Florida’s OEL to offer VPK vouchers to students in the Pre-K 4 year.

                    <a href="https://www.fldoe.org/schools/early-learning/parents/vpk-parents.stml">To Learn More Visit Their Site</a>\\n\\n
                    
                    <i>Angeline Country Day admits students regardless of race, religion, color, creed, ethnicity, national origin, gender, gender identity, sexual orientation, or disability who possess the motivation, ability, and character which would enable them to succeed in our School community. Angeline Country Day does not discriminate on the basis of race, religion, color, creed, ethnicity, national origin, gender, gender identity, sexual orientation, or disability in the administration of its educational policies, admission policies, financial aid program, or athletic and other School-administered programs.</i>\\n\\n
`}
                    reverse={true}
                />


                <Footer/>
            </Box>
        </>
    );
}

export default Admissions
