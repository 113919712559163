import {Box} from "@mui/material";

import ContentTile from "../assets/components/ContentTile.tsx";
import ResponsiveAppBar from "../assets/components/ResponsiveAppBar.tsx";
import Footer from "./Footer.tsx";

function Academics() {
    return (
        <>
            <Box sx={{}}>
                <ResponsiveAppBar/>
                <ContentTile
                    id={"a-day-in-the-life"}
                    header={"A Day in the Life of an Angeline Student"}
                    subtitle={"Explore. Discover. Grow."}
                    body={`At Angeline Country Day, every day is an opportunity to explore new ideas, dive deep into 
                    learning, and connect with a vibrant community.\\n\\n
                    Morning: The day begins with students gathering for our Morning Circle, a time for reflection, 
                    sharing, and setting intentions. This is followed by engaging inquiry-based lessons, where students 
                    tackle real-world challenges in subjects like math, science, language arts, and social studies.\\n\\n
                    Mid-Morning: Students transition to specialist classes, such as Spanish, Art, and Physical Education,
                     where they develop skills that expand their global awareness and creative expression.\\n\\n
                    Afternoon: After lunch and outdoor play, students delve into project-based learning, collaborating
                     on inquiries that span across our transdisciplinary themes, like "How We Organize Ourselves" or 
                     "Sharing the Planet." These units help students make connections between the classroom and the
                      world beyond.\\n\\n
                    End of Day: As the school day winds down, students participate in reflection and discussion, 
                    reinforcing what they’ve learned and preparing for the challenges ahead.\\n\\n
                    Every day at Angeline Country Day is dynamic, purposeful, and rooted in the belief that learning 
                    is an adventure.\\n\\n`}
                    reverse={true}
                />
                <ContentTile
                    id={"curriculum"}
                    header={"Curriculum"}
                    subtitle={"An Education Rooted in Inquiry"}
                    body={`At Angeline Country Day, our academic program is built on the International Baccalaureate 
                    (IB) Primary Years Programme (PYP), a globally recognized curriculum designed to cultivate critical 
                    thinking, creativity, and collaboration. Through inquiry-based learning, our students engage in 
                    transdisciplinary units of inquiry that explore big ideas across multiple subject areas.\\n\\n
                    Core Areas of Study Include:\\n
                    <b>Language & Literacy</b>: Building strong communication skills through reading, writing, and speaking.\\n
                     <b>Mathematics</b>: Developing problem-solving skills through real-world application and mathematical inquiry.\\n
                     <b>Science & Social Studies</b>: Fostering curiosity about the world through hands-on experiments and exploration of historical events.\\n
                     <b>The Arts & Physical Education</b>: Encouraging creative expression and physical wellness.\\n\\n\\n`}
                    reverse={true}
                    backgroundColor={"2"}
                />
                <ContentTile
                    id={"community"}
                    header={"Our Community"}
                    subtitle={"Rooted in Tradition, Thriving Together"}
                    body={`Our close-knit community at Angeline Country Day is one where families, teachers, and 
                    students work together to create a supportive and enriching environment. We celebrate diversity, 
                    promote inclusivity, and believe that learning is best achieved when rooted in strong relationships.\\n
                    We invite you to become part of our community, where every child is known, valued, and supported in
                     their journey to becoming a lifelong learner.`}
                    reverse={true}
                />
                <ContentTile
                    id={"stem"}
                    header={"STEM"}
                    subtitle={`Fostering Innovation through STEM and Classical Scientific Inquiry`}
                    body={`In our IB-infused science program, students are encouraged to think critically and
                        globally, exploring the natural world through the lens of both modern STEM initiatives and 
                        classical scientific principles. Hands-on experimentation is at the heart of the program, 
                        allowing students to explore robotics, coding, and aquaponics, while drawing connections to 
                        the work of early scientists like Archimedes, Galileo, and Newton. Through inquiry-based 
                        learning, students engage with real-world problems, integrating classical methods of observation
                         and reasoning with advanced STEM tools.\\n\\n

                        The IB framework promotes international-mindedness, pushing students to consider the ethical 
                        implications of science and technology. The inclusion of global environmental projects, such as 
                        aquaponics, helps students connect classroom learning to global sustainability efforts, blending 
                        classical ideas of nature and the cosmos with modern innovation. Students are encouraged to think
                         across disciplines, fostering innovation while honoring the foundations of scientific inquiry.\\n\\n`}
                    reverse={true}
                    backgroundColor={"2"}
                />
                <Footer/>
            </Box>
        </>
    );
}

export default Academics
