import logo from '../angeline-country-day-logo.svg';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {useEffect, useState} from "react";
import {Box} from "@mui/material";
import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";

const pages = [
    {
        title: 'OUR SCHOOL',
        link: '/',
        children: [
            {title: 'WELCOME', link: '/#welcome',},
            {title: 'MISSION', link: '/#mission',},
            {title: 'WHY COUNTRY DAY', link: '/#why-country-day',},
            // {title: 'LEADERSHIP', link: '/#our-leadership',},
        ],
    },
    {
        title: 'ACADEMICS', link: '/academics',
        children: [
            {title: 'A DAY IN THE LIFE', link: '/academics/#a-day-in-the-life',},
            {title: 'CURRICULUM', link: '/academics/#curriculum',},
            {title: 'COMMUNITY', link: '/academics/#community',},
            {title: 'STEM', link: '/academics/#stem',},
        ]
    },
    {
        title: 'ADMISSIONS', link: '/admissions',
        children: [
            {title: 'APPLICATION PROCESS', link: '/admissions/#application',},
            {title: 'TUITION', link: '/admissions/#tuition',},
            {title: 'FINANCIAL AID', link: '/admissions/#scholarships',},


        ]
    },
    {title: "CONTACT US", link: '/contact-us', children: []},
];

function ResponsiveAppBar() {
    const [navbarBg, setNavbarBg] = useState('transparent');
    const [navbarIconSize, setNavbarIconSize] = useState(270);
    const location = useLocation();
    const navigate = useNavigate();
    const [isTogglerOpen, setIsTogglerOpen] = useState(false); // Track if the toggler is open


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const oParam = queryParams.get('o');

        if (oParam) {
            localStorage.setItem('o', oParam);
            queryParams.delete('o');

            navigate(`${location.pathname}?${queryParams.toString()}`, {replace: true});
        }
    }, [location, navigate]);

    useEffect(() => {
        if (window.location.hash != '') {
            scrollIntoView(window.location.hash.replace('#', ''));
        }
    });
    const scrollIntoView = (location: string) => {
        setTimeout(() => {
            document.getElementById(location)!.scrollIntoView();
            const currentUrl = window.location.href;
            const updatedUrl = currentUrl.split('#')[0];

            history.pushState(null, '', updatedUrl);
        }, 250);

    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;

            // Check if the user is on the homepage ('/') and the toggler is not open
            if (location.pathname === '/' && !isTogglerOpen) {
                if (scrollY > 100) {
                    setNavbarBg('#242144'); // Navy color when scrolled beyond 100px
                    setNavbarIconSize(90);
                } else {
                    setNavbarBg('transparent'); // Transparent background for top 100px
                    setNavbarIconSize(250);
                }
            } else {
                // Always show navy when not on the homepage or when the toggler is open
                setNavbarBg('#242144');
                setNavbarIconSize(90);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Initial check in case the user is not scrolled on load
        handleScroll();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location, isTogglerOpen]); // Trigger effect when the route or toggler state changes

    const handleTogglerClick = () => {
        setIsTogglerOpen((prevState) => !prevState);
    };

    return (
        <Navbar expand="md" fixed={"top"} style={{backgroundColor: navbarBg, transition: 'background-color 0.3s ease'}}>
            <Navbar.Brand href="/">
                <img src={logo} alt={"Angeline Country Day"}
                     style={{marginLeft: 40, width: navbarIconSize, maxWidth: 300, transition: 'all 0.3s ease'}}/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"
                           style={{marginRight: 20, color: 'white', borderColor: 'white'}}
                           onClick={handleTogglerClick}/>
            <Navbar.Collapse id="basic-navbar-nav" className={"basic-navbar-nav"}>
                <Nav className="justify-content-end align-items-center" style={{width: "100%"}}>
                    {pages.map((page, index) => {
                        if (page.children.length > 0) {
                            return <React.Fragment key={`${index}-nav-wrapper`}><NavDropdown
                                key={`${index}-nav-dropdown`} title={page.title} id="basic-nav-dropdown"
                                style={{fontWeight: 'bold'}}>
                                {page.children.map((child) => {
                                    return <NavDropdown.Item href={child.link}
                                                             key={child.link}>{child.title}</NavDropdown.Item>
                                })}
                            </NavDropdown>
                                {index < pages.length - 1 ?
                                    <Box sx={{
                                        color: '#F2D8A7',
                                        marginLeft: 2,
                                        marginRight: 2,
                                        display: {xs: 'none', lg: 'block',}
                                    }}>|</Box> : null}
                            </React.Fragment>
                        } else {
                            return <React.Fragment key={`${index}-nav-wrapper`}><Nav.Link href={page.link}
                                                                                          style={{fontWeight: 'bold'}}
                                                                                          key={page.link}>{page.title}</Nav.Link>
                                {index < pages.length - 1 ?
                                    <Box sx={{
                                        color: '#F2D8A7',
                                        marginLeft: 2,
                                        marginRight: 2,
                                        display: {xs: 'none', lg: 'block',}
                                    }}>|</Box> : null}
                            </React.Fragment>
                        }

                    })}
                </Nav>
            </Navbar.Collapse>

        </Navbar>)
}

export default ResponsiveAppBar;