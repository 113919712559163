import {Box, Typography} from "@mui/material";

import ResponsiveAppBar from "../assets/components/ResponsiveAppBar.tsx";
import Footer from "./Footer.tsx";
import CareerForm from "../assets/components/CareerForm.tsx";

function Careers() {
    return (
        <>
            <Box sx={{mt:20}}>
                <ResponsiveAppBar/>
                <Box className={'tile'} sx={{mb:20,}}>
                    <br/>
                    <Typography variant={"h4"}>Looking to join our team? Apply below.</Typography>
                    <Box sx={{display:'flex', justifyContent:'center', flexDirection:{xs: 'column-reverse', md:'row',} }}>
                        <Box sx={{mr:{md:5, xs:0}, mt:5,}}>
                            <CareerForm/>
                        </Box>
                        {/*<Box sx={{maxWidth:'90vw', ml:'auto', mr:'auto',}} className={`image`}>*/}
                        {/*    <Typography sx={{marginTop:'unset',textAlign:'center',fontSize:{md:'4rem', xs:'2.5rem',}, fontWeight:'900'}}>Contact Us</Typography>*/}
                        {/*    <Box component={"img"} src={letsTalk} alt={`Let's Talk`} sx={{maxWidth:{md: '40vw', sm:'50%', xs: '90%'} }}/>*/}
                        {/*</Box>*/}

                    </Box>
                </Box>
                <Footer/>
            </Box>
        </>
    );
}

export default Careers
