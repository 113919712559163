import {Box, Link, Typography, useMediaQuery, useTheme} from "@mui/material";
import accent1 from "../assets/accent_1.svg";
import accent2 from "../assets/accent_2.svg";
import accent3 from "../assets/accent_3.png";
import Button from "@mui/material/Button";

function Footer() {
    const theme = useTheme();
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Box sx={{
                backgroundColor: '#242144', minHeight: 200, color: 'white', pb: 1, position:'relative',
            }}>

                <Box sx={{
                    backgroundColor: '#242144',
                    minHeight: 350,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    position: 'relative',
                }}>
                    <Box component={"img"} sx={{
                        position:'absolute',
                        top:0,
                        left:0,
                        height:300,
                    }} src={accent1} alt={"accent1"}/>
                    <Box component={"img"} sx={{
                        position:'absolute',
                        top:10,
                        right:0,
                        height:35,
                    }} src={accent2} alt={"accent2"}/>

                    <Typography
                        variant={isMediumScreen ? 'h4' : 'h3'}
                        sx={{ fontWeight: 'bold', mt: 4, color: "white", whiteSpace: 'pre-line' }}
                    >
                        {isSmallScreen ? "Interested in learning\nmore?" : "Interested in learning more?"}
                    </Typography>                    <Button sx={{m: 3, fontSize: '1.5rem', borderRadius: '15px',zIndex:5,}} variant={"contained"}
                            href={"/contact-us"}>Contact Us</Button>

                </Box>

                <Box component={"img"} sx={{
                    position:'absolute',
                    top:60,
                    right:0,
                    height:{xs: 300, sm: 400, md: 500},
                }} src={accent3} alt={"accent3"}/>

                <Box className={'footer'} sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', }}>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: {xs: 'center', sm: 'start'}, mt: 5, width: 200,}}>
                        <Link sx={{fontWeight: 'bold'}}>Our School</Link>
                        <Link href="/#welcome">Welcome</Link>
                        <Link href="/#mission">Mission</Link>
                        <Link href="/#why-country-day">Why Country Day</Link>
                        {/*<Link href="/#our-leadership">Leadership</Link>*/}
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: {xs: 'center', sm: 'start'}, mt: 5, width: 200,}}>
                        <Link sx={{fontWeight: 'bold'}}>Academics</Link>
                        <Link href="/academics#a-day-in-the-life">A Day In The Life</Link>
                        <Link href="/academics#curriculum">Curriculum</Link>
                        <Link href="/academics#community">Community</Link>
                        <Link href="/academics#stem">Science</Link>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: {xs: 'center', sm: 'start'}, mt: 5, width: 200,}}>
                        <Link sx={{fontWeight: 'bold'}}>Admissions</Link>
                        <Link href="/admissions#application">Application Process</Link>
                        <Link href="/admissions#tuition">Tuition</Link>
                        <Link href="/admissions#scholarships">Financial Aid</Link>
                        <Link href="/contact-us">Contact Us</Link>
                        <Link href="/careers">Careers</Link>
                    </Box>
                </Box>
                {/*<Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', mt:4,}}>*/}
                {/*    /!*<Box component={"img"} sx={{width:300,}} src={logo}/>*!/*/}
                {/*    <Box>*/}
                {/*        <Typography variant="h5" sx={{fontWeight:'bold', mb:1,}}>Stay Connected</Typography>*/}
                {/*        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center',}}>*/}
                {/*            <Link href={"https://facebook.com/angelinecountryday"}><Box component={"img"} src={facebook} sx={{width:30,}}></Box></Link>*/}
                {/*            <Box sx={{width:30,}}/>*/}
                {/*            <Link href={"https://instagram.com/angelinecountryday"}><Box component={"img"} src={instagram} sx={{width:30,}}></Box></Link>*/}
                {/*        </Box>*/}

                {/*    </Box>*/}
                {/*</Box>*/}
                <Box sx={{mt: 4,}}>
                    <Typography variant={"caption"}>© {(new Date()).getFullYear()} ANGELINE COUNTRY DAY</Typography>

                </Box>
            </Box>
        </>
    );
}

export default Footer
