import { useState } from 'react';
import {
    TextField,
    Button,
    MenuItem,
    Select,
    FormControl,
    CircularProgress,
    Typography,
    Box,
} from '@mui/material';
import icon from '../../../public/favicon.svg';

function CareerForm() {
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [complete, setComplete] = useState(false);

    const [formData, setFormData] = useState({
        school: 'angeline-country-day-career',
        name: '',
        age: '',
        email: '',
        phoneNumber: '',
        message: '',
        origin: '',
    });

    const handleChange = (event: { target: { name: string; value: string; }; }) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setFormSubmitting(true);
        try {
            formData.origin = localStorage.getItem('o') ?? '';
            const response = await fetch('https://z36yqxxizkk52vzf3bn4dyeqei0fnxsn.lambda-url.us-east-1.on.aws/', {
                method: 'POST',
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                setComplete(true);
            } else {
                setErrorMessage('There has been an error while attempting to submit this form. Please try again later.');
            }

            setFormSubmitting(false);
        } catch (error) {
            setErrorMessage('An error has occurred while attempting to submit this form. Please try again later.');
            setFormSubmitting(false);
        }
    };

    return (
        complete ? (
            <Box sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Box component="img" src={icon} sx={{ height: 100, filter: 'brightness(0%)' }} />
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    Thank you for reaching out!<p />We will be in contact soon.
                </Typography>
            </Box>
        ) : (
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth sx={{display:'none'}}>
                    <Select
                        name="school"
                        value={formData.school}
                        onChange={handleChange}
                        displayEmpty
                        required
                        sx={{ textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
                    >
                        <MenuItem value="angeline-country-day-career">Angeline Country Day</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <Select
                        name="age"
                        value={formData.age}
                        onChange={handleChange}
                        displayEmpty
                        required
                        sx={{ textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:1, }}
                        placeholder={"Select Position"}
                    >
                        <MenuItem sx={{display:'none'}} value="" disabled>Select Position</MenuItem>
                        <MenuItem value="principal">Principal</MenuItem>
                        <MenuItem value="assistant-principal">Assistant Principal</MenuItem>
                        <MenuItem value="teacher">Teacher</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    name="name"
                    label="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    fullWidth
                    margin="normal"
                    sx={{ textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
                />
                <TextField
                    name="email"
                    label="Email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    fullWidth
                    margin="normal"
                    sx={{ textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
                />
                <TextField
                    name="phoneNumber"
                    label="Phone Number"
                    type="tel"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                    fullWidth
                    margin="normal"
                    sx={{ textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
                />
                <TextField
                    name="message"
                    label="Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    sx={{ textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
                />
                {/*<TextField*/}
                {/*    type="file"*/}
                {/*    name="file"*/}
                {/*    onChange={handleFileChange}*/}
                {/*    inputProps={{ accept: '.pdf,.doc,.docx' }}*/}
                {/*    fullWidth*/}
                {/*    margin="normal"*/}
                {/*    sx={{ textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}*/}
                {/*/>*/}
                <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
                <Button type="submit" variant="contained" color="primary" disabled={formSubmitting}>
                    {formSubmitting ? <CircularProgress size={24} /> : "Submit"}
                </Button>
            </form>
        )
    );
}

export default CareerForm;